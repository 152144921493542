<template>
  <div class="admin-export-history">
    <div class="top-header">
      <div class="top-header-wrap">
        <img
          src="@/assets/images/common/bul_arr_left_gray.svg"
          class="back-button"
          @click="$router.push({name: 'Dashboard'})"
        />
        솔루션 테마 내보내기 내역
      </div>
    </div>
    <div class="export-history-wrap">
      <div class="export-history-section">
        <div class="title">최근 6개월 처리 내역</div>
        <table class="table-pc">
          <thead>
            <tr>
              <th>처리일시</th>
              <th>테마명</th>
              <th>처리자</th>
              <th>솔루션</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody v-if="list.length">
            <tr v-for="history in list" :key="history.id">
              <td>{{ history.createdAt | date }}</td>
              <td>{{ history.userThemeName }}</td>
              <td>
                {{ history.userEmail }} <span>({{ history.userName }})</span>
              </td>
              <td>
                <img
                  class="solution-logo"
                  v-if="history.solution.logo"
                  :src="history.solution.logo.url"
                />
                <p class="solution-name" v-else>{{ history.solution.name }}</p>
                <span> (ID : {{ history.solutionUserId }})</span>
              </td>
              <td
                :class="history.isSucceeded === 1 ? 'status-s' : 'status-f'"
                @click="
                  history.isSucceeded === 1 ? '' : failPopup(history.message)
                "
              >
                {{ history.isSucceeded === 1 ? "성공" : "실패" }}
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="list.length" class="table-m">
          <ul v-for="history in list" :key="history.id">
            <li>
              <div class="th">처리일시</div>
              <div class="td">{{ history.createdAt | date }}</div>
            </li>
            <li>
              <div class="th">테마명</div>
              <div class="td">{{ history.userThemeName }}</div>
            </li>
            <li>
              <div class="th">처리자</div>
              <div class="td">
                {{ history.userEmail }} <span>({{ history.userName }})</span>
              </div>
            </li>
            <li>
              <div class="th">솔루션</div>
              <div class="td">
                <img
                  class="solution-logo"
                  v-if="history.solution.logo"
                  :src="history.solution.logo.url"
                />
                <p class="solution-name" v-else>{{ history.solution.name }}</p>
                <span> (ID : {{ history.solutionUserId }})</span>
              </div>
            </li>
            <li>
              <div class="th">상태</div>
              <div
                class="td"
                :class="history.isSucceeded === 1 ? 'status-s' : 'status-f'"
              >
                {{ history.isSucceeded === 1 ? "성공" : "실패" }}
              </div>
            </li>
          </ul>
        </div>
        <div v-if="!list.length" class="data-empty">
          <img src="@/assets/images/admin/icon_empty.svg" />
          <p>데이터가 없습니다.</p>
        </div>
        <div class="pagination" v-show="isPagination">
          <button
            type="button"
            class="pagination--prev"
            v-show="pagination.prev"
            @click="goPage(pagination.prev.start)"
          ></button>
          <button
            type="button"
            v-for="page in pagination.pages"
            :key="page"
            :class="{'pagination--active': pagination.page === page}"
            @click="goPage(page)"
          >
            {{ page }}
          </button>
          <button
            type="button"
            class="pagination--next"
            v-show="pagination.next"
            @click="goPage(pagination.next.start)"
          ></button>
        </div>
      </div>
    </div>
    <div class="footer">ⓒ QPICK</div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const ModuleAlert = createNamespacedHelpers("ModuleAlert");

export default {
  data() {
    return {
      page: 1,
      pagination: {},
      list: []
    };
  },
  mounted() {
    this.themeExportHistory();
  },
  computed: {
    // pagination 유무
    isPagination() {
      return this.pagination.totalCount > this.pagination.perPage;
    }
  },
  methods: {
    // 테마 내보내기 내역
    themeExportHistory() {
      const params = {
        page: this.page,
        perPage: 20,
        perBlock: 5,
        sortBy: "id-"
      };

      this.$store
        .dispatch("ModuleTheme/actThemeExportHistory", params)
        .then(response => {
          const data = response.data;
          this.list = data.list;
          this.pagination = data.header;

          this.pagination.pages = [];
          for (
            let i = this.pagination.startPage;
            i <= this.pagination.endPage;
            i++
          ) {
            this.pagination.pages.push(i);
          }
        });
    },
    // 페이지 이동
    goPage(page) {
      this.page = page;
      this.themeExportHistory();
      window.scrollTo(0, 0);
    },
    // 실패 내역 팝업
    failPopup(message) {
      this.setAlert({
        isVisible: true,
        message: `내보내기 실패사유<br>${message}`
      });
    },
    ...ModuleAlert.mapMutations(["setAlert"])
  }
};
</script>

<style lang="scss">
.admin-export-history {
  @media screen and (max-width: 1023px) {
    background: #ffffff;
  }

  .top-header {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    height: 56px;
    line-height: 56px;

    .top-header-wrap {
      max-width: 1248px;
      margin: 0 auto;
      padding: 0 16px;

      img {
        margin-right: 16px;
        cursor: pointer;
      }
    }
  }

  .export-history-wrap {
    max-width: 1248px;
    margin: 0 auto;
    margin-top: 16px;
    padding: 0 16px;

    @media screen and (max-width: 1023px) {
      padding: 0;
    }

    .export-history-section {
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      padding: 24px;
      box-sizing: border-box;
      margin-bottom: 20px;

      @media screen and (max-width: 1023px) {
        padding: 0px 16px 24px;
        box-shadow: none;
        border-radius: 0px;
      }
    }

    .title {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 16px;

      @media screen and (max-width: 1023px) {
        font-size: 14px;
      }
    }

    table {
      border-top: 1px solid #b7b7b7;
      border-bottom: 1px solid #b7b7b7;
      width: 100%;

      @media screen and (max-width: 1023px) {
        display: none;
      }

      thead {
        height: 56px;
        border-bottom: 2px solid #606060;
      }

      tr {
        height: 64px;
        border-bottom: 1px solid #f1f1f1;

        &:last-child {
          border-bottom: none;
        }
      }

      td {
        text-align: center;

        span {
          color: #8d8d8d;
        }
      }

      .status-s {
        color: #54c7a2;
        font-weight: 700;
      }

      .status-f {
        color: #d14343;
        font-weight: 700;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    .table-m {
      display: none;
      border-top: 1px solid #606060;
      border-bottom: 1px solid #606060;

      @media screen and (max-width: 1023px) {
        display: block;
      }

      ul {
        border-bottom: 1px solid #f1f1f1;
        padding: 24px 0;

        &:last-child {
          border-bottom: none;
        }

        li {
          display: flex;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }

          .th {
            width: 60px;
            margin-right: 16px;
          }

          .td {
            width: calc(100% - 70px);

            span {
              color: #8d8d8d;
            }
          }

          .status-s {
            color: #54c7a2;
            font-weight: 700;
          }

          .status-f {
            color: #d14343;
            font-weight: 700;
            text-decoration-line: underline;
          }
        }
      }
    }

    .solution-logo {
      width: 67px;
      vertical-align: -4px;
    }

    .solution-name {
      display: inline-block;
    }

    .data-empty {
      background: #f7f9fc;
      border-radius: 8px;
      text-align: center;
      padding: 241px 0 261px 0;
      margin-top: 16px;

      @media screen and (max-width: 1023px) {
        padding: 163px 0 300px 0;
      }

      p {
        font-weight: 500;
        font-size: 14px;
        color: #309574;
        margin-top: 27px;
      }
    }

    .pagination {
      display: flex;
      justify-content: center;
      gap: 8px;
      padding-top: 16px;

      button {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        border: 1px solid #54c7a2;
        color: #54c7a2;
        font-size: 14px;
      }
      &--active {
        background-color: #54c7a2;
        color: #ffffff !important;
      }
      &--prev,
      &--next {
        border: 1px solid #8d8d8d !important;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
      &--prev {
        margin-right: 16px;
        background-image: url($path + "ico_arr_left.svg");
      }
      &--next {
        margin-left: 16px;
        background-image: url($path + "ico_arr_right.svg");
      }
    }
  }

  .footer {
    margin: 40px 0;
    color: #8d8d8d;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
  }
}
</style>
